<template>
  <div class="max-w-full">
    <div class="px-4 sm:px-16 fixed bg-gradient-to-b from-white to-blue-100 w-full p-4 select-none h-28 z-40">
      <div class="flex items-center">
        <span v-if="!can_go_forward" class="mr-4 font-extrabold text-blue-600 text-xl w-1/3">You can move to next slide after:</span>
        <timer ref="timer_questions" @finished="can_go_forward=true;" v-if="course_time && !can_go_forward && current_stage=='listening'" :seconds="course_time" class="w-24"/>
        <timer @finished="last_submit" v-if="course_time && current_stage=='answering'" :seconds="course_time" class="w-24"/>
        <stop-icon v-if="$store.getters['auth/isAdmin']" @click="make_next({move_answering: true})" class="h-8 w-8 text-red-600 hover:text-red-800 cursor-pointer"/>
      </div>
      <div class="flex items-center" v-if="current_stage == 'listening'">
        <span class="mr-4 font-extrabold text-blue-600 text-xl">Pages:</span>
        <span class="mr-4 font-extrabold text-blue-600 text-xl">{{current_page}} / {{pages_count}}</span>
        <arrow-right-icon v-if="can_go_forward && current_page < pages_count" @click="make_next" class="text-blue-600 w-8 h-8 hover:text-blue-800 cursor-pointer ml-6"/>
        <button v-if="current_page == pages_count" @click="make_next" type="button" class="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
          Go to test
        </button>
      </div>
      <button
          :disabled="!can_submit"
          @click="last_submit"
          v-else
          :class="`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 ${can_submit ? 'hover:bg-gray-50' : 'bg-gradient-to-r hover:from-yellow-600 hover:to-yellow-400'} sm:mt-0 sm:col-start-1 sm:text-sm`">
        Submit answers
        <save-icon
            class="flex-shrink-0 h-6 w-6 text-blue-600 mx-4 hover:text-blue-800 cursor-pointer"
            aria-hidden="true"/>
      </button>
    </div>
    <div class="w-full content-container pt-28">
      <img v-if="current_stage=='listening'" :src="img" id="course-preview" class="h-full mx-auto" />
      <div v-else-if="current_stage == 'answering'" class="pl-4 pb-80">
        <div v-for="(question, q) in questions" :key="q" class="mb-8">
          <p>{{question.question}}</p>
          <fieldset v-for="(answer, i) in question.answers" :key="i">
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input :ref="'check'+q+i" @change="changedCheckbox($event, q, i)" v-model="answer['correct']" id="comments" aria-describedby="comments-description" name="comments" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <p id="comments-description" class="text-gray-500">{{answer.text}}</p>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <button
          :disabled="!can_submit"
          v-if="current_stage == 'answering'"
          @click="last_submit"
          :class="`fixed bottom-0 bg-gradient-to-r from-blue-900 to-blue-500 ${can_submit ? 'hover:from-blue-900 hover:to-blue-800' : 'hover:from-yellow-600 hover:to-yellow-400'} mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2  text-base font-medium text-white sm:mt-0 sm:col-start-1 sm:text-sm`">
        Submit answers
        <save-icon
            class="flex-shrink-0 h-6 w-6 text-white mx-4 cursor-pointer"
            aria-hidden="true"/>
      </button>
    </div>
  </div>
</template>

<script>
import {ArrowRightIcon,SaveIcon, StopIcon} from '@heroicons/vue/solid'
import timer from "../components/timer";
import {mapGetters} from "vuex";

export default {
  name: "Start",
  components: {
    SaveIcon,
    ArrowRightIcon,
    timer,
    StopIcon
  },
  data() {
    return {
      loading: true,
      listening_id: null,
      img: null,
      timerCount: 30,
      pages_count: 1,
      current_page: 1,
      current_stage: 'listening',
      questions: [],
      end: false,
      stopCheck: false,
      can_go_forward: false,
      tabFocus: true
    }
  },
  watch: {
    tabFocus: function (val) {
      if (!val) {
        this.$refs.timer_questions.pause()
      } else {
        this.$refs.timer_questions.play()
      }
    }
  },
  methods: {
    detectFocusOut() {
      let inView = false;

      const onWindowFocusChange = (e) => {
        if ({ focus: 1, pageshow: 1 }[e.type]) {
          if (inView) return;
          this.tabFocus = true;
          inView = true;
        } else if (inView) {
          this.tabFocus = !this.tabFocus;
          inView = false;
        }
      };

      window.addEventListener('focus', onWindowFocusChange);
      window.addEventListener('blur', onWindowFocusChange);
      window.addEventListener('pageshow', onWindowFocusChange);
      window.addEventListener('pagehide', onWindowFocusChange);
    },
    changedCheckbox(e, q, a) {
      if (!this.stopCheck) {
        if (e.target.checked) {
          let ans = this.questions[q].answers
          for (let i = 0; i < this.questions[q].answers.length; i++) {
            if (i != a && this.$refs['check'+q+i].checked) {
              console.log(a, i)
              this.stopCheck = true
              this.$refs['check'+q+i].click()
            }
          }
        }
      } else {
        this.stopCheck = false
      }
    },
    async listen_next() {
      let {data} = await this.axios.get(`service/course-listening-handler/${this.listening_id}/`)
      this.img = process.env.VUE_APP_ROOT_API.split('api')[0] + data.img
      this.pages_count = data.pages_count
      this.current_page = data.current_page
      this.current_stage = data.current_stage
    },
    async make_next(req_data = {}) {
      if (this.current_stage == 'answering') {
        await this.last_submit()
      } else {
        this.can_go_forward=false
        let {data} = await this.axios.post(`service/course-listening-handler/${this.listening_id}/`, req_data)
        this.img = process.env.VUE_APP_ROOT_API.split('api')[0] + data.img
        this.pages_count = data.pages_count
        this.current_page = data.current_page
        this.current_stage = data.current_stage
        this.questions = data.questions
      }
    },
    async last_submit() {
      this.end = true
      let {data} = await this.axios.post(`service/course-listening-handler/${this.listening_id}/`, {
        answers: this.questions
      })

      this.$store.commit('auth/setLastCourseMessage', data.message)
      if (data.status == 'ok') {
        this.$router.push('/success-certificate')
      } else {
        this.$store.commit('auth/setCanAgain', data.started < 2)
        this.$router.push({name: 'FailedCertificate', params: {id: this.$route.params.id}})
      }
    }
  },
  async mounted() {
    this.detectFocusOut()
    let pk = null

    try {
      let res = await this.axios.post('service/course-listening/', {
        course: this.$route.params.id
      })

      pk = res.data.pk
    } catch (e) {
      if (e.response.data.course[0].includes('second_')) {
        pk = e.response.data.course[0].split("_")[1]
      }
      // if (e.response.data.)
    }

    this.listening_id = pk
    if (this.$store.state.auth.listen) {
      await this.listen_next()
    } else {
      await this.listen_next()
      await this.make_next({move_answering: true})
      this.$store.commit('auth/setListen', true)
    }

  },
  beforeUnmount() {
    if (!this.end) {
      this.axios.post(`service/course-listening-handler/${this.listening_id}/`, {
        finish: true
      })
    }
  },
  beforeRouteLeave (to, from , next) {
    if (!this.end) {
      const answer = window.confirm('Do you really want to leave? It will not be able to come back here!')
      if (answer) {
        this.axios.post(`service/course-listening-handler/${this.listening_id}/`, {
          finish: true
        })
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    can_submit() {
      let can = true;

      this.questions.forEach(e=>{
        let one = false;

        e.answers.forEach(a=>{
          if ('correct' in a) {
            one = one || a['correct']
          }
        })

        can = can && one
      })
      return can
    },
    course_time() {
      if (this.user && this.current_stage == 'listening') {
        let course = this.user.courses.find(e=>e.pk == this.$route.params.id)
        return course.seconds_per_page
      } else if (this.user && this.current_stage == 'answering') {
        let course = this.user.courses.find(e=>e.pk == this.$route.params.id)
        return course.seconds_per_question * course.questions_number
      }
      return 0
    }
  }
}
</script>

<style scoped>
.content-container {
  height: calc(100vh - theme('padding.28'));
}
</style>