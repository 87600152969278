<template>
  <p class="font-extrabold text-blue-600 text-3xl w-full">
    {{Math.floor(timerCount / 60)}} min {{timerCount - Math.floor(timerCount / 60) * 60}} sec
  </p>
</template>

<script>

export default {

  data() {
    return {
      timerEnabled: true,
      timerCount: 0
    }
  },
  props: {
    seconds: {
      type: Number
    }
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },

    timerCount: {
      handler(value) {

        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
            if (this.timerCount == 0) {
              this.$emit('finished')
            }
          }, 1000);
        }

      },
      immediate: true // This ensures the watcher is triggered upon creation
    }

  },
  methods: {

    play() {
      this.timerEnabled = true;
    },

    pause() {
      this.timerEnabled = false;
    }

  },
  beforeMount() {
    this.timerCount = this.seconds
  }

}

</script>